<template>
  <sub-page
      :title="$t('Templates')"
      v-model="$store.state.newsletter.emailPageData"
      header-route="newsletter"
      :header-action="openDialog"
      :header-action-text="$t('AddTemplate')"
  >

    <template #default>
      <ws-data-table
          class="mt-5"
          :items="items"
          :headers="headers"
          :row-action="openTemplate"
      >

        <template #item.name="{item}">
          <h5 :style="`color : ${wsACCENT}`"> {{ item.name }}</h5>
        </template>

        <template #item.type="{item}">
          <h5> {{ !item.business_id ? 'WeStudy' : $t('Custom')  }}</h5>
        </template>

        <template #item.actions="{item}">
          <div v-if="item.business_id"   class="d-flex">
            <v-btn @click.stop="openDelete(item)" icon :color="wsACCENT">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </div>
        </template>


      </ws-data-table>
    </template>

    <template #dialog>
      <!-- Delete Template-->
      <ws-dialog
          v-model="displayDeleteDialog"
          :title="$t('DeleteTemplate')"
          display-confirm-delete
          close-delete
          @delete="deleteTemplate"
      ></ws-dialog>
      <!-- Add Template-->
      <ws-dialog
          v-model="displayDialog"
          @save="addTemplate"
          :title="$t('AddTemplate')"
      >
        <ws-text-field
            v-model="entityData.name"
            :label="$t('Name')"
        />

      </ws-dialog>
      <!-- Edit Template Design-->
      <v-dialog
          v-model="editDesign"
          fullscreen>
        <email-design
            v-if="editDesign"
            @close="editDesign = false"
            :uuid="selectedTemplate"
            template
        />
      </v-dialog>
      <v-fade-transition>
        <v-sheet v-if="editDesign" style="position:fixed; top : 0; bottom: 0;left: 0;right: 0" ></v-sheet>
      </v-fade-transition>

    </template>

  </sub-page>


</template>

<script>
import {mapActions} from "vuex";
import emailDesign from "@/components/pages/businessDashboard/businessNewsletter/email/emailConstructor/emailDesign";

export default {
  name: "newslettersTable",
  components : {
    emailDesign
  },
  data() {
    return {
      items : [],
      entityData : {},
      displayDialog : false,
      displayDeleteDialog : false,
      editDesign : false,
      selectedTemplate : '',
    }
  },
  computed : {
    headers() {
      return [
        { text : this.$t('Name') , value : 'name' } ,
        { text : this.$t('Type') , value : 'type' } ,
        { value : 'actions' , width : 50 , sortable : false} ,
      ]
    }
  },
  methods : {
    ...mapActions('newsletter', [
        'GET_BUSINESS_NEWSLETTER_TEMPLATES',
        'ADD_EMAIL_TEMPLATE',
        'DELETE_EMAIL_TEMPLATE'
    ]),

    openTemplate(item) {
      this.selectedTemplate = item.uuid
      this.editDesign = true
    },
    openDelete(item) {
      this.selectedTemplate = item.uuid
      this.displayDeleteDialog = true
    },
    async deleteTemplate() {
      let result = await this.DELETE_EMAIL_TEMPLATE(this.selectedTemplate)
      if ( !result ) {
        return
      }
      let index = this.items.findIndex(el => el.uuid === this.selectedTemplate )
      if ( index === -1 ) {
        return
      }
      this.items.splice(index , 1)
      this.displayDeleteDialog = false
      this.notify(this.$t('TemplateDeleted'))
    },

    async addTemplate() {
      let result = await this.ADD_EMAIL_TEMPLATE(this.entityData)
      if ( !result ) {
        return
      }
      this.items.unshift(result)

      this.selectedTemplate = result.uuid
      this.displayDialog = false
      this.editDesign = true
    },
    openDialog() {
      this.entityData = {}
      this.displayDialog = true
    },

    async initPage() {
      let result = await this.GET_BUSINESS_NEWSLETTER_TEMPLATES()
      if ( !result || result === true ) {
        return
      }
      result.reverse()
      this.items = result
    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>